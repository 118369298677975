<template lang="pug">

v-card
  .create-new-modal
    v-card-title
      h2.card-title {{ 'modals.supply.create.title' | translate }}

    v-card-content
      v-text-field.mb-3(
        :label="$t('base.date')"
        :disabled="true"
        v-model="form.created_at")
      v-select.mb-2(
        :label="$t('base.ingredient')"
        :disabled="true"
        :options="ingredientsList"
        v-model="form.ingredient_id")
      v-select.mb-2(
        :label="$t('base.provider')"
        :disabled="true"
        :options="providersList"
        v-model="form.provider_id")
      v-text-field.mb-2(
        :label="$t('modals.supply.create.weight')"
        :disabled="!canEditWeight"
        v-model="form.weight")
      v-text-field.mb-3(
        :label="$t('modals.supply.create.price_of_unit')"
        v-model="form.price")

    v-card-actions
      v-btn(
        :disabled="!isIngredientSelected || !isPriceValid || !isWeightValid || !isSupplierSelected"
        :loading="loading"
        @click="onUpdate") {{ 'actions.save' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formateDate } from '@/util/utils'

export default {
  name: 'CreateNewModal',

  props: {
    item: Object,
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    form: {
      ingredient_id: null,
      provider_id: null,
      weight: '',
      price: '',
      created_at: null
    },
    loading: false,
    maxDate: new Date()
  }),

  computed: {
    ...mapGetters(['ingredientsList', 'providersList']),
    isIngredientSelected () {
      return this.form.ingredient_id !== null
    },
    isSupplierSelected () {
      return this.form.provider_id !== null
    },
    isPriceValid () {
      return !!this.form.price
    },
    isWeightValid () {
      return !!this.form.weight
    },
    canEditWeight () {
      return parseFloat(this.item.remainder) > 0
    }
  },

  async mounted () {
    await this.fetchIngredientsList()
    await this.fetchProvidersList()
    this.form.ingredient_id = this.item.ingredient_id
    this.form.provider_id = this.item.provider_id
    this.form.weight = this.item.weight
    this.form.price = this.item.price.replace(' ', '').replace(',', '.')
    this.form.created_at = formateDate(new Date(this.item.created_at))
  },

  methods: {
    ...mapActions([
      'fetchIngredientsList',
      'fetchProvidersList',
      'updateSupply'
    ]),

    async onUpdate () {
      this.loading = true
      const { data, error } = await this.updateSupply({ supplyID: this.item.id, payload: this.form })
      this.loading = false
      if (error) {
        for (const key in data.errors) {
          this.$toasted.show(data.errors[key], {
            position: 'bottom-center',
            duration: 5000
          })
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/sass/abstracts/_variables.scss";

  .create-new-modal {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__select {
      width: calc(100% - 5.5rem);
    }

    &__qr {
      display: inline-flex;
      outline: none;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }

    &__tabs-wrap {
      // padding: 1rem 2rem 2rem;
      background-color: $color-gray-light-3;
      border: .1rem solid $color-gray-light-2;
      border-radius: .8rem;
    }

    &__tabs-body {
      padding: 0 2rem 2rem;
    }

    &__tabs-list {
      padding: 1rem 2rem 0;
      display: flex;
      border-bottom: .1rem solid $color-gray-light-2;
    }

    &__tabs-item {
      padding-bottom: .7rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: $color-gray;
      cursor: pointer;
      user-select: none;

      &--active {
        color: $color-black;
        border-bottom: .3rem solid $color-brand;
      }
    }

    &__tabs-item:not(:last-child) {
      margin-right: 2rem;
    }
  }
</style>
